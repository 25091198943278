import Link from "next/link";
import {
  faInstagram,
  faLinkedin,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import type { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import Button from "../ui-elements/Button";

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const Footer = () => (
  <footer
    id="footer"
    className={classnames(
      "z-10 flex w-full items-center justify-center gap-12 bg-base-200 p-8 text-base-content md:p-12",
    )}
  >
    <div className="flex min-h-[420px] w-full max-w-screen-xl flex-col items-start justify-between gap-12">
      <div className="flex w-full flex-col justify-between gap-8 md:flex-row md:gap-0">
        <Button
          variant="outline"
          leftIcon={faArrowUp}
          onClick={() => scrollTop()}
          className="h-fit border-base-100/50 hover:border-base-100 hover:bg-base-100/10"
        >
          back to top
        </Button>
        <div className="flex w-full flex-col-reverse gap-8 self-center sm:flex-row md:w-fit md:gap-16">
          <SocialLinks />
          <LinkList
            title="Learn more"
            links={[
              { name: "Home", href: "/" },
              { name: "Blog", href: "/blog" },
            ]}
          />
          <div className="w-full md:w-fit">
            <LinkList
              title="Get started"
              links={[{ name: "Privacy policy", href: "/privacy" }]}
            />
            <div className="flex flex-col gap-2.5">
              <Link
                href="https://apps.apple.com/us/app/vital-score-ai/id6736663088"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="cursor-pointer pl-2 text-left hover:opacity-50">
                  Download app
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <h6 className="text-base-content/50">© Vital Routines 2024</h6>
    </div>
  </footer>
);

interface LinkListProps {
  title: string;
  links: { name: string; href: string }[];
}
const LinkList = ({ title, links }: LinkListProps) => (
  <div className="flex w-full flex-col divide-y divide-base-300 md:w-32">
    <h6 className="p-2 text-base font-medium">{title}</h6>
    <div className="flex flex-col gap-2 p-2 ">
      {links.map((link) => (
        <Link
          key={link.name}
          className="text-base-content hover:opacity-60"
          href={link.href}
        >
          {link.name}
        </Link>
      ))}
    </div>
  </div>
);

const socialLinks: SocialLink[] = [
  {
    name: "x",
    href: "https://x.com/VitalScoreAI",
    icon: faXTwitter,
  },
  {
    name: "linkedin",
    href: "https://www.linkedin.com/company/vital-score-ai/",
    icon: faLinkedin,
  },
  {
    name: "instagram",
    href: "https://www.instagram.com/vitalhealthscore",
    icon: faInstagram,
  },
  {
    name: "youtube",
    href: "https://www.youtube.com/@VitalScoreAI",
    icon: faYoutube,
  },
];

interface SocialLink {
  name: string;
  href: string;
  icon: IconDefinition;
}

const SocialLinks = () => (
  <div className=" flex w-full flex-col md:w-28 ">
    <h6 className="p-2 text-base font-medium">Community</h6>
    <div className="self-stretch border-t border-base-300 pb-1" />
    <div className="grid w-fit grid-cols-4 gap-3 p-2">
      {socialLinks.map((link) => (
        <Link
          key={link.name}
          className="flex aspect-square h-4 w-4 items-center justify-center rounded-md text-base-content hover:opacity-60"
          href={link.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={link.icon} className="h-4 w-4" />
        </Link>
      ))}
    </div>
  </div>
);

export default Footer;
