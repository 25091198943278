import Image from "next/image";
import Link from "next/link";
import { faArrowUpRight } from "@fortawesome/pro-regular-svg-icons";
import classnames from "classnames";
import { motion } from "framer-motion";

import Button from "../ui-elements/Button";

const GetYourScore = () => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.2 }}
      viewport={{ once: true }}
      className="flex w-screen max-w-screen-xl flex-row items-center justify-around gap-8 px-6 sm:px-12"
    >
      <div className="flex max-w-[503px] flex-col items-start gap-8 md:gap-12 lg:gap-16">
        <motion.h2
          className={classnames(
            "w-full text-left font-semibold text-base-content/30",
            "text-2xl sm:text-3xl md:text-4xl",
          )}
        >
          <span className="whitespace-nowrap bg-gradient-to-r from-base-content/60 to-base-content bg-clip-text font-semibold text-transparent">
            Get your score
          </span>{" "}
          and take control of your health
        </motion.h2>
        <Link
          href="https://apps.apple.com/us/app/vital-score-ai/id6736663088"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            variant="primary"
            rightIcon={faArrowUpRight}
            className={classnames(
              "!h-12 !w-48 !rounded-[1em]",
              "bg-gradient-to-t from-grapefruit-500 to-rose-500",
            )}
          >
            Start free trial
          </Button>
        </Link>
      </div>
      <Image
        src="/images/home/home-light.jpg"
        alt="Vital health score app preview"
        width={307}
        height={640}
        className={classnames(
          "-mr-28 sm:-mr-0 dark:hidden",
          "w-[211px] sm:w-[259px] md:w-[307px]",
          "h-[440px] sm:h-[540px] md:h-[640px]",
        )}
      />
      <Image
        src="/images/home/home-dark.jpg"
        alt="Vital health score app preview"
        width={307}
        height={640}
        className={classnames(
          "-mr-28 hidden sm:-mr-0 dark:block",
          "w-[211px] sm:w-[259px] md:w-[307px]",
          "h-[440px] sm:h-[540px] md:h-[640px]",
        )}
      />
    </motion.section>
  );
};

export default GetYourScore;
