import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { faArrowUpRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

const Hero = () => {
  const [loaded, setLoaded] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll = () => {
    setScrollTop(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section
      className={classnames(
        "relative flex w-full max-w-screen-xl flex-col items-center justify-center text-center",
        "px-6 sm:px-12",
      )}
    >
      <div className="flex max-w-[700px] flex-col items-center">
        <h1
          className={classnames(
            "bg-gradient-to-r from-base-content/60 to-base-content bg-clip-text font-extrabold text-transparent",
            "text-3xl sm:text-4xl md:text-5xl",
            "mb-2 md:mb-3 lg:mb-4",
          )}
        >
          Track your health with
          <br />
          one simple score
        </h1>
        <p
          className={classnames(
            "mb-8 font-normal text-base-content/60 sm:mb-12",
            "text-base sm:text-lg md:text-xl",
          )}
        >
          Health is complicated, we make it simple.
        </p>
        <Link
          href="https://apps.apple.com/us/app/vital-score-ai/id6736663088"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            className={classnames(
              "transition-all hover:scale-105 hover:bg-grapefruit-500 hover:shadow-xl active:scale-100",
              "flex flex-row items-center justify-center gap-0",
              "h-[48px] w-[287px] sm:h-[58px] sm:w-[327px]",
              "rounded-2xl font-semibold text-white",
              "bg-gradient-to-t from-grapefruit-500 to-rose-500",
            )}
          >
            View in App Store
            <FontAwesomeIcon
              icon={faArrowUpRight}
              className="ml-2 h-3.5 w-3.5"
            />
          </button>
        </Link>
      </div>
      <div className="hidden sm:block">
        <Image
          src="/images/home/vitality-chart-dark.svg"
          alt="preventative health routine calendar and holistic protocol stack"
          width={1280}
          height={718}
          sizes="80vh"
          quality={90}
          priority={true}
          className={classnames(
            "hidden dark:block",
            "mt-12 sm:mt-16 md:mt-20 lg:mt-28",
            "transition-all duration-300 ease-in-out",
            loaded ? "opacity-100" : "opacity-0",
            scrollTop > 50 ? "scale-100" : "scale-105",
          )}
          onLoad={() => setLoaded(true)}
          draggable={false}
        />
        <Image
          src="/images/home/vitality-chart-light.svg"
          alt="preventative health routine calendar and holistic protocol stack"
          width={1280}
          height={718}
          sizes="80vh"
          quality={90}
          priority={true}
          className={classnames(
            "block dark:hidden",
            "mt-12 sm:mt-16 md:mt-20 lg:mt-28",
            "transition-all duration-300 ease-in-out",
            loaded ? "opacity-100" : "opacity-0",
            scrollTop > 50 ? "scale-100" : "scale-105",
          )}
          onLoad={() => setLoaded(true)}
          draggable={false}
        />
      </div>
      <div className="relative mx-12 mt-12 aspect-square w-3/4 sm:hidden">
        <Image
          src="/images/home/vitality-chart-mobile-dark.svg"
          alt="preventative health routine calendar and holistic protocol stack"
          fill={true}
          sizes="90vh"
          quality={90}
          priority={true}
          className={classnames(
            "hidden dark:block",
            "transition-all duration-300 ease-in-out",
            loaded ? "opacity-100" : "opacity-0",
            scrollTop > 50 ? "scale-100" : "scale-105",
          )}
          onLoad={() => setLoaded(true)}
          draggable={false}
        />
        <Image
          src="/images/home/vitality-chart-mobile-light.svg"
          alt="preventative health routine calendar and holistic protocol stack"
          fill={true}
          sizes="90vh"
          quality={90}
          priority={true}
          className={classnames(
            "dark:hidden",
            "transition-all duration-300 ease-in-out",
            loaded ? "opacity-100" : "opacity-0",
            scrollTop > 50 ? "scale-100" : "scale-105",
          )}
          onLoad={() => setLoaded(true)}
          draggable={false}
        />
      </div>
    </section>
  );
};

export default Hero;
