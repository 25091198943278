import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  faArrowRight,
  faArrowUpRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import ThemeButton from "~/components/theme/ThemeButton";
import Button from "../ui-elements/Button";

const Header = () => {
  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll = () => {
    setScrollTop(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const router = useRouter();
  const segment = router.asPath.split("/")[1];

  return (
    <header
      className={classnames(
        "font-display fixed left-0 top-0 z-50 flex w-full flex-col items-center justify-center bg-base-100 p-4 transition-all duration-150",
        segment == "blog" && scrollTop < 501
          ? "bg-transparent backdrop-filter-none"
          : "bg-base-100/80 backdrop-blur-sm",
        segment == "blog" && scrollTop < 501 && "dark",
        segment == "expert-application" && "hidden",
      )}
    >
      <div className="flex w-full max-w-screen-xl flex-row items-center justify-between ">
        <Link href="/" className="flex flex-row items-center gap-4">
          <Image
            src={"/images/vital-logo-alt.png"}
            alt="Vital logo"
            width={40}
            height={40}
            priority
          />
          <Image
            className={"hidden dark:sm:block"}
            src="/vital-white.svg"
            alt="Vital logotype"
            width={46.2}
            height={16}
            priority
          />
          <Image
            className={"hidden sm:block dark:hidden"}
            src="/vital-black.svg"
            alt="Vital logotype"
            width={46.2}
            height={16}
            priority
          />
        </Link>
        <Link
          className={classnames(
            "bg-base-content/30 transition-all duration-300 hover:scale-105 hover:bg-base-content/50",
            "w-fit flex-row items-center gap-2.5 rounded-full px-4 py-2 text-sm text-base-100",
            "absolute left-1/2 -translate-x-1/2",
            "hidden sm:flex",
            segment && "sm:hidden",
          )}
          href="/blog"
        >
          Our Vision
          <FontAwesomeIcon icon={faArrowRight} className="h-3.5 w-3.5" />
        </Link>
        <div className="flex flex-row gap-2">
          <div className="flex flex-row gap-2">
            <Link
              href="https://apps.apple.com/us/app/vital-score-ai/id6736663088"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="solid" rightIcon={faArrowUpRight}>
                Get app
              </Button>
            </Link>
            <ThemeButton />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
